import React                from "react";
import PropTypes            from "prop-types";

// Components
import ConnectorButton      from "./ConnectorButton";
import GoogleAuth           from "Components/Utils/Login/GoogleAuth";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Connector Input
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorInput(props) {
    const { type, value, error, onChange, ...data } = props;


    // Do the Render
    if (type === "google") {
        return <GoogleAuth
            scope={data.scope}
            error={error}
            onChange={(value) => onChange(data.name, value)}
        />;
    }

    if (type === "button") {
        return <ConnectorButton
            {...data}
            error={error}
            onChange={(value) => onChange(data.name, value)}
        />;
    }

    return <InputField
        {...data}
        value={value}
        error={error}
        onChange={onChange}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorInput.propTypes = {
    type     : PropTypes.string.isRequired,
    value    : PropTypes.string.isRequired,
    error    : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ConnectorInput;
