import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import ConnectorLogView     from "./ConnectorLogView";

// Dashboard
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Connector Log Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorLogContent(props) {
    const { type, withNavigate } = props;
    const { load, loadFilter } = useList("connectorLog", type);

    const { logID }     = Navigate.useParams();
    const adminNavigate = Hooks.useAdminGoto(type);
    const logNavigate   = Hooks.useLogGoto(type);

    const data = Store.useState("connectorLog");
    const { loading, canEdit, list, total, clients, connectors, filters, sort } = data;


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();

    // Variables
    const isAdmin       = type === "ADMIN";
    const isFlow        = type === "FLOW";
    const isIntegration = type === "INTEGRATION";


    // Handles the Log change
    React.useEffect(() => {
        if (logID) {
            startAction("VIEW", logID);
        }
    }, [ logID ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        const { clientID, flowID, integrationID, conversationHash } = Utils.getValue(list, "logID", elemID);

        if (action.isClient) {
            adminNavigate(clientID, "SETTINGS", "LOG_CONNECTORS");
        } else if (action.isFlow) {
            adminNavigate(clientID, "SETTINGS", "FLOWS", flowID, "LOG_CONNECTORS");
        } else if (action.isIntegration) {
            adminNavigate(clientID, "SETTINGS", "INTEGRATIONS", integrationID, "LOG_CONNECTORS");
        } else if (action.isConversation) {
            adminNavigate(clientID, "CONVERSATIONS", conversationHash);
        } else {
            startAction(action, elemID);
        }
    };

    // Returns true if the Flow is hidden
    const hideFlow = (elemID) => {
        const flowID = Utils.getValue(list, "logID", elemID, "flowID");
        return isFlow || !flowID;
    };

    // Handles the Close
    const handleClose = () => {
        endAction();
        if (withNavigate) {
            logNavigate("LOG_CONNECTORS");
        }
    };

    // Returns true if the Integration is hidden
    const hideIntegration = (elemID) => {
        const integrationID = Utils.getValue(list, "logID", elemID, "integrationID");
        return isIntegration || !integrationID;
    };

    // Returns true if the Conversation is hidden
    const hideConversation = (elemID) => {
        const conversationID = Utils.getValue(list, "logID", elemID, "conversationID");
        return !conversationID;
    };


    // Do the Render
    return <>
        <FilterList onFilter={loadFilter} values={filters}>
            <FilterItem
                name="search"
                label="GENERAL_SEARCH"
                icon="search"
                hasClear
                shrinkLabel
            />
            <FilterItem
                isHidden={!clients.length}
                type="select"
                name="clientID"
                label="CLIENTS_SINGULAR"
                options={clients}
                noneText="GENERAL_ALL"
                shrinkLabel
                hasClear
            />
            <FilterItem
                type="select"
                name="connectorCode"
                label="CONNECTORS_SINGULAR"
                options={connectors}
                noneText="GENERAL_ALL"
                shrinkLabel
            />
            <FilterItem
                type="date"
                name="fromDate"
                label="GENERAL_FROM_DATE"
                hasClear
            />
            <FilterItem
                type="date"
                name="toDate"
                label="GENERAL_TO_DATE"
                hasClear
            />
        </FilterList>

        <Table
            fetch={load}
            sort={sort}
            none="CONNECTORS_LOG_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs={isFlow || isIntegration}
            hasFilter
        >
            <TableHead>
                <TableHeader field="createdTime"       message="GENERAL_TIME"           minWidth="150" maxWidth="150" />
                <TableHeader field="clientName"        message="CLIENTS_SINGULAR"       isHidden={!isAdmin} />
                <TableHeader field="isError"           message="GENERAL_RESULT"         maxWidth="90" />
                <TableHeader field="connectorTypeName" message="CONNECTORS_SINGULAR"    />
                <TableHeader field="modelName"         message="CONNECTORS_LOG_MODEL"   isHidden={isFlow || isIntegration} />
                <TableHeader field="conversationID"    message="CONVERSATIONS_SINGULAR" maxWidth="120" align="center" isHidden={isIntegration} />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                    <TableCell message={elem.createdTimeText}   />
                    <TableCell message={elem.clientName}        />
                    <TableCell message={elem.errorText}         className={elem.errorClass} />
                    <TableCell message={elem.connectorTypeName} />
                    <TableCell message={elem.modelName}         />
                    <TableCell message={elem.conversationText}  />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={handleAction} canEdit={canEdit}>
                <TableAction action="VIEW"         message="CONNECTORS_LOG_VIEW_TITLE" navigate={withNavigate} />
                <TableAction action="CLIENT"       message="CLIENTS_VIEW_TITLE"        isHidden={!isAdmin} />
                <TableAction action="FLOW"         message="FLOWS_VIEW_TITLE"          hide={hideFlow} />
                <TableAction action="INTEGRATION"  message="INTEGRATIONS_VIEW_TITLE"   hide={hideIntegration} />
                <TableAction action="CONVERSATION" message="CONVERSATIONS_VIEW_TITLE"  hide={hideConversation} />
            </TableActionList>
        </Table>

        <ConnectorLogView
            open={action.isVCE}
            isAdmin={isAdmin}
            isFlow={isFlow}
            isIntegration={isIntegration}
            elemID={elemID}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorLogContent.propTypes = {
    type         : PropTypes.string.isRequired,
    withNavigate : PropTypes.bool,
};

export default ConnectorLogContent;
