import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import TextField            from "Components/Utils/Inputs/TextField";



/**
 * The WABA Template Foot
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateFoot(props) {
    const { isDisabled, getValue, getError, onChange } = props;

    const { textLength } = Store.useState("wabaTemplate");


    // Handles the Change
    const handleChange = (name, value) => {
        onChange("Footer", name, value);
    };


    // Do the Render
    return <>
        <TextField
            name="footerText"
            label="WABA_TEMPLATES_FOOTER_TITLE"
            value={getValue("footerText")}
            error={getError("footerText")}
            onChange={handleChange}
            isDisabled={isDisabled}
            maxLength={textLength}
            withEmoji
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateFoot.propTypes = {
    isDisabled : PropTypes.bool.isRequired,
    getValue   : PropTypes.func.isRequired,
    getError   : PropTypes.func.isRequired,
    onChange   : PropTypes.func.isRequired,
};

export default WabaTemplateFoot;
