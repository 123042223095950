import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Contact Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactFilter(props) {
    const { open, filters, clientID, onClose, onSubmit } = props;

    const {
        contactOrigins, tongues, customFields, tags,
        hasInstagram, hasTelegram,
    } = Store.useState("contact");


    // The Initial Data
    const initialData = Hooks.useInitialData({
        period     : 0,
        fromDate   : "",
        toDate     : "",
        fullName   : "",
        email      : "",
        cellphone  : "",
        instagram  : "",
        telegram   : "",
        address    : "",
        externalID : "",
        origin     : [],
        tongueID   : [],
        tagID      : [],
    }, customFields, true);

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("contact", open, initialData, filters, onSubmit, { contactID : 0, clientID });


    // Do the Render
    const hasOne = (hasInstagram && !hasTelegram) || (!hasInstagram && hasTelegram);

    return <FilterDialog
        open={open}
        title="CONTACTS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <Columns>
            <InputField
                className={hasOne ? "columns-double" : ""}
                name="fullName"
                label="GENERAL_FULL_NAME"
                value={data.fullName}
                onChange={handleChange}
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                onChange={handleChange}
            />
            <InputField
                type="tel"
                name="cellphone"
                label="GENERAL_CELLPHONE"
                value={data.cellphone}
                onChange={handleChange}
            />

            <InputField
                isHidden={!hasInstagram}
                name="instagram"
                label="CONTACTS_INSTAGRAM"
                value={data.instagram}
                onChange={handleChange}
            />
            <InputField
                isHidden={!hasTelegram}
                name="telegram"
                label="CONTACTS_TELEGRAM"
                value={data.telegram}
                onChange={handleChange}
            />
            <InputField
                name="address"
                label="CONTACTS_ADDRESS"
                value={data.address}
                onChange={handleChange}
            />

            <FieldsInputs
                fields={customFields}
                data={data}
                onChange={handleChange}
                forFilter
            />
        </Columns>

        <InputField
            type="chooser"
            name="origin"
            label="GENERAL_ORIGINS"
            options={contactOrigins}
            value={data.origin}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="tongueID"
            label="LANGUAGES_NAME"
            options={tongues}
            value={data.tongueID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="tagID"
            label="TAGS_NAME"
            options={tags}
            value={data.tagID}
            onChange={handleChange}
        />

        <InputField
            name="externalID"
            label="GENERAL_EXTERNAL_ID"
            value={data.externalID}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
    clientID : PropTypes.number,
};

export default ContactFilter;
