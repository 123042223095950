import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Connector Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorLogView(props) {
    const { open, isAdmin, isFlow, isIntegration, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("connectorLog", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="connector-log"
        title="CONNECTORS_LOG_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns lastDouble>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdTimeText}
            />
            <ViewField
                label="GENERAL_RESULT"
                value={elem.errorText}
                viewClass={elem.errorClass}
            />
            <ViewField
                isHidden={!isAdmin}
                label="CLIENTS_SINGULAR"
                value={elem.clientName}
                showEmpty
            />
            <ViewField
                isHidden={isFlow || !elem.flowID}
                label="FLOWS_SINGULAR"
                value={elem.flowName}
            />
            <ViewField
                isHidden={isIntegration || !elem.integrationID}
                label="INTEGRATIONS_SINGULAR"
                value={elem.integrationName}
            />
            <ViewField
                isHidden={!elem.conversationID}
                label="CONVERSATIONS_SINGULAR"
                value={elem.conversationText}
                showEmpty
            />

            <ViewField
                isHidden={!elem.connectorID}
                label="CONNECTORS_SINGULAR"
                value={elem.connectorName}
            />
            <ViewField
                label="CONNECTORS_SINGULAR"
                value={elem.connectorTypeName}
            />
            <ViewField
                label="CONNECTORS_LOG_METHOD"
                value={elem.method}
            />
        </Columns>

        <ViewField
            label="GENERAL_URL"
            value={elem.url}
        />
        <ViewField
            label="CONNECTORS_LOG_USER_PASSWORD"
            value={elem.userPass}
        />
        <ViewField
            label="CONNECTORS_LOG_HEADERS"
            value={Utils.jsonToHtml(elem.headers)}
        />
        <ViewField
            label="CONNECTORS_LOG_PARAMS"
            value={Utils.jsonToHtml(elem.params)}
        />
        <ViewField
            label="GENERAL_RESPONSE"
            value={Utils.jsonToHtml(elem.response)}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorLogView.propTypes = {
    open          : PropTypes.bool.isRequired,
    isAdmin       : PropTypes.bool.isRequired,
    isFlow        : PropTypes.bool.isRequired,
    isIntegration : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    elemID        : PropTypes.number,
};

export default ConnectorLogView;
