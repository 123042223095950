import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Connector Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorHeader(props) {
    const { data, href, isEdit } = props;

    const { charging, elem } = Store.useState("connector");


    // Generates the Title
    const title = React.useMemo(() => {
        if (charging) {
            return "GENERAL_LOADING_DOTS";
        }
        if (isEdit) {
            return data.name;
        }
        return "CONNECTORS_ADD_TITLE";
    }, [ charging, isEdit, data.name ]);


    // Do the Render
    return <Header
        message={title}
        icon="connector"
        href={href}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorHeader.propTypes = {
    data   : PropTypes.object.isRequired,
    href   : PropTypes.string.isRequired,
    isEdit : PropTypes.bool.isRequired,
};

export default ConnectorHeader;
