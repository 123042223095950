import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { AssistantModel }   from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    models    : [],
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ASSISTANT_MODEL_LOADING" });
    },

    /**
     * Fetches the Assistant Model List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await AssistantModel.getAll(params);
        data.sort = params;
        dispatch({ type : "ASSISTANT_MODEL_LIST", data });
    },

    /**
     * Fetches a single Assistant Model
     * @param {Function} dispatch
     * @param {Number}   assistantModelID
     * @returns {Promise}
     */
    async fetchElem(dispatch, assistantModelID) {
        const data = await AssistantModel.getOne({ assistantModelID });
        dispatch({ type : "ASSISTANT_MODEL_ELEM", data });
    },

    /**
     * Fetches the Assistant Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await AssistantModel.getEditData();
        dispatch({ type : "ASSISTANT_MODEL_EDIT", data });
    },

    /**
     * Edits/Creates an Assistant Model
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editAssistantModel(dispatch, data) {
        return AssistantModel.edit(data);
    },

    /**
     * Deletes an Assistant Model
     * @param {Function} dispatch
     * @param {Number}   assistantModelID
     * @returns {Promise}
     */
    deleteAssistantModel(dispatch, assistantModelID) {
        return AssistantModel.delete({ assistantModelID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.modelTypeName = NLS.get("SELECT_ASSISTANT_MODEL_TYPES", elem.modelType);
    elem.inputText     = "";
    elem.outputText    = "";

    const inputPrice  = Commons.formatPrice(elem.inputCost, "US$", 4);
    const outputPrice = Commons.formatPrice(elem.outputCost, "US$", 4);
    if (elem.isText) {
        elem.inputText  = NLS.format("ASSISTANTS_MODELS_TOKEN_PRICE", inputPrice);
        elem.outputText = NLS.format("ASSISTANTS_MODELS_TOKEN_PRICE", outputPrice);
    } else if (elem.isAudio) {
        elem.outputText = NLS.format("ASSISTANTS_MODELS_MINUTE_PRICE", outputPrice);
    }

    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ASSISTANT_MODEL_LIST", "ASSISTANT_MODEL_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ASSISTANT_MODEL_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ASSISTANT_MODEL_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "ASSISTANT_MODEL_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            models    : action.data.models,
        };

    case "ASSISTANT_MODEL_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            models    : action.data.models,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
