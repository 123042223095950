import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";



/**
 * Returns the Custom Field Key
 * @param {Object} field
 * @param {Number} tongueID
 * @returns {String}
 */
function getKey(field, tongueID) {
    if (tongueID && (field.inputType === "text" || field.inputType === "textarea")) {
        return `${field.customFieldID}-${tongueID}`;
    }
    return String(field.customFieldID);
}

/**
 * Returns the data for the Custom Field Value Inout
 * @param {Object}   data
 * @param {Object[]} fields
 * @returns {{type: String, options: Array.<Object>}}
 */
function getInput(data, fields) {
    if (!fields.length) {
        return { type : "text", options : [] };
    }

    const fieldID = Number(data.fieldID || data.field || data.name);
    const field   = Utils.getValue(fields, "key", fieldID);
    const type    = field.type === "file" || field.type === "number" ? "text" : field.type;
    const options = [];

    for (const [ index, option ] of (field.options || []).entries()) {
        options.push({
            key   : index + 1,
            value : option,
        });
    }

    return { type, options };
}

/**
 * Returns the Type for the given item
 * @param {Object} item
 * @param {Object} data
 * @param {Object} selects
 * @returns {String}
 */
function getInputType(item, data, selects) {
    if (item.useType) {
        const fields = eval(item.getFields)(data, selects);
        return getInput(data, fields).type;
    }
    return item.type;
}

/**
 * Returns the Select Options for the given item
 * @param {Object} item
 * @param {Object} data
 * @param {Object} selects
 * @returns {Array.<Object>}
 */
function getOptions(item, data, selects) {
    if (item.getSelect) {
        return eval(item.getSelect)(data, selects);
    }
    if (item.useSelect) {
        const fields = eval(item.getFields)(data, selects);
        return getInput(data, fields).options;
    }
    return selects[item.select] || item.select;
}

/**
 * Returns true if the field can be filtered
 * @param {Object}  field
 * @param {Boolean} forFilter
 * @returns {Boolean}
 */
function canFilter(field, forFilter) {
    if (!forFilter || !field) {
        return true;
    }
    return field.inputType !== "file" && field.inputType !== "password";
}

/**
 * Returns true if the field can be compared
 * @param {Object}  field
 * @param {Boolean} forFilter
 * @returns {Boolean}
 */
function canCompare(field, forFilter) {
    if (!forFilter || !field) {
        return false;
    }
    return field.inputType === "date" || field.inputType === "number";
}



/**
 * Parses the Fields of an Element
 * @param {Object} elem
 * @returns {Void}
 */
function parseElem(elem) {
    elem.fields     = Object.values(elem.fields || {});
    elem.fieldsData = {};

    for (const field of elem.fields) {
        const key = getKey(field, field.tongueID || 0);
        elem[key] = field.value;

        switch (field.inputType) {
        case "toggle":
            field.value = Utils.toYesNo(field.value);
            break;
        case "number":
            field.value = !field.value ? "0" : String(field.value);
            break;
        case "date":
            field.value = field.value.split("-").reverse().join("-");
            break;
        case "select":
            field.value = field.options[field.value - 1] || field.value;
            break;
        case "list":
            if (field.value) {
                field.value = JSON.parse(field.value).join(", ");
            }
            break;
        case "password":
            field.value = "**********";
            break;
        case "file":
            field.url   = process.env.REACT_APP_INTERNALS + field.clientID + field.value;
            field.value = NLS.get("GENERAL_FILE");
            break;
        default:
        }

        elem.fieldsData[key] = field;
    }

    elem.visibleFields = elem.fields.filter(({ isHidden }) => !isHidden);
}




// The public API
export default {
    getKey,
    getInput,
    getInputType,
    getOptions,
    canFilter,
    canCompare,

    parseElem,
};
