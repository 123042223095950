import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import ContactHeader        from "./ContactHeader";
import ContactDetails       from "./ContactDetails";
import ContactDialogs       from "./ContactDialogs";
import ChannelIconList      from "Components/Utils/Common/ChannelIconList";
import ColorList            from "Components/Utils/Common/ColorList";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Contact List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("contact", type);

    const { contactID } = Navigate.useParams();
    const clientID      = Hooks.useClientID();
    const navigate      = Hooks.useGoto();

    const { hasAccount, hasMarketing } = Store.useState("permission");
    const {
        loading, canEdit, list, total, filters, sort,
        elem, module, columns, customFields,
    } = Store.useState("contact");

    const { openDetails } = Store.useAction("core");
    const { saveColumns } = Store.useAction("app");
    const { exportContacts } = Store.useAction("contact");


    // The Current State
    const [ checked,   setChecked   ] = React.useState([]);
    const [ mergeIDs,  setMergeIDs  ] = React.useState([]);
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Add the Hooks for the Details
    Hooks.useItemDetails("contact", contactID);

    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isView) {
            endAction();
            if (elemID !== contactID) {
                navigate("CONTACTS", elemID);
            } else {
                openDetails();
            }
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = exportContacts(clientID, filters);
            await Utils.download(source, "contacts.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Load
    const handleLoad = (params) => {
        load(params, false);
    };

    // Handles the Checked
    const handleChecked = (checkIDs) => {
        setChecked(checkIDs);
        setMergeIDs(checkIDs);
    };

    // Handles the Columns
    const handleColumns = (columns, save) => {
        saveColumns(module, clientID, columns, save);
    };


    // Returns true if the Block is hidden
    const hideBlock = (elemID) => {
        const isBlocked = Utils.getValue(list, "contactID", elemID, "isBlocked");
        return !canEdit || isBlocked;
    };

    // Returns true if the Unblock is hidden
    const hideUnblock = (elemID) => {
        const isBlocked = Utils.getValue(list, "contactID", elemID, "isBlocked");
        return !canEdit || !isBlocked;
    };

    // Returns true if the Conversation is hidden
    const hideConversation = (elemID) => {
        const isBlocked = Utils.getValue(list, "contactID", elemID, "isBlocked");
        return !canEdit || isBlocked;
    };


    // Variables
    const withNavigation = type === "CLIENT";
    const withDetails    = !!elem.id;


    // Do the Render
    return <>
        <Main withNavigation={withNavigation} withDetails={withDetails}>
            <ContactHeader
                startAction={handleAction}
                checked={checked}
                isExporting={exporting}
            />

            <Content>
                <Table
                    isLoading={loading}
                    fetch={handleLoad}
                    sort={sort}
                    none="CONTACTS_NONE_AVAILABLE"
                    checked={checked}
                    setChecked={handleChecked}
                    hasCheckAll
                    columnData={columns}
                    onColumnEdit={handleColumns}
                >
                    <TableHead>
                        <TableHeader field="fullName"             message="GENERAL_NAME"                 isTitle isFlex smallSpace isDefault />
                        <TableHeader field="providers"            message="CHANNELS_NAME"                isFlex noSorting smallSpace isDefault />
                        <TableHeader field="email"                message="GENERAL_EMAIL"                isDefault />
                        <TableHeader field="cellphone"            message="GENERAL_CELLPHONE"            isDefault />
                        <TableHeader field="languageName"         message="LANGUAGES_SINGULAR"           isDefault />
                        <TableHeader field="qualificationPercent" message="CONVERSATIONS_QUALIFICATION"  align="center" isDefault />
                        <TableHeader field="origin"               message="GENERAL_ORIGIN"               />
                        <TableHeader field="address"              message="CONTACTS_ADDRESS"             />
                        <TableHeader field="externalID"           message="GENERAL_EXTERNAL_ID"          />
                        <TableHeader field="dontSendCampaign"     message="CONTACTS_DONT_SEND_CAMPAIGNS" isHidden={!hasMarketing} />
                        <TableHeader field="isBlocked"            message="CONTACTS_BLOCKED"             />
                        <TableHeader field="tags"                 message="TAGS_NAME"                    isFlex noSorting smallSpace />
                        <TableHeader field="createdTime"          message="GENERAL_CREATED"              />
                        {customFields.map((field) => <TableHeader key={field.id} field={String(field.id)} message={field.name} />)}
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow
                            key={elem.contactID}
                            elemID={elem.contactID}
                            isSelected={elem.contactID === contactID}
                        >
                            <TableCell message={elem.fullName} />
                            <TableCell><ChannelIconList items={elem.providerItems} /></TableCell>
                            <TableCell message={elem.email}         />
                            <TableCell message={elem.cellphone}     />
                            <TableCell message={elem.languageName}  />
                            <TableCell className={elem.qualificationColor} message={elem.qualificationPercent} tooltip={elem.qualificationTooltip} />
                            <TableCell message={elem.originName}    />
                            <TableCell message={elem.address}       />
                            <TableCell message={elem.externalID}    />
                            <TableCell message={elem.dontSendText}  />
                            <TableCell message={elem.isBlockedText} />
                            <TableCell><ColorList list={elem.tagList} /></TableCell>
                            <TableCell message={elem.createdDate}   />
                            {customFields.map((field) => <TableCell key={field.id} message={elem.fieldsData[field.id]?.value ?? ""} />)}
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={handleAction} canEdit={canEdit}>
                        <TableAction action="VIEW"         message="CONTACTS_VIEW_TITLE"           />
                        <TableAction action="EDIT"         message="CONTACTS_EDIT_TITLE"           />
                        <TableAction action="COMBINE"      message="CONTACTS_MERGE_TITLE"          />
                        <TableAction action="ASSIGN"       message="ACCOUNTS_ASSIGN_ACCOUNT_TITLE" isHidden={!hasAccount} />
                        <TableAction action="BLOCK"        message="CONTACTS_BLOCK_TITLE"          hide={hideBlock} />
                        <TableAction action="BLOCK"        message="CONTACTS_UNBLOCK_TITLE"        hide={hideUnblock} />
                        <TableAction action="DELETE"       message="CONTACTS_DELETE_TITLE"         />
                        <TableAction action="CONVERSATION" message="CONVERSATIONS_CREATE_TITLE"    hide={hideConversation} />
                    </TableActionList>
                </Table>
            </Content>
        </Main>

        <ContactDetails startAction={startAction} />

        <ContactDialogs
            action={action}
            elemID={elemID}
            startAction={startAction}
            endAction={endAction}
            fetch={fetch}
            loadFilter={loadFilter}
            mergeIDs={mergeIDs}
            setMergeIDs={setMergeIDs}
            checked={checked}
            setChecked={handleChecked}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ContactList;
