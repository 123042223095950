import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Hooks                from "Utils/Hooks";

// Components
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Account Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountEdit(props) {
    const { open, elemID, clientID, onClose, onSubmit } = props;

    const { elem, editElem, customFields } = Store.useState("account");
    const { fetchEditData, editAccount } = Store.useAction("account");


    // The Initial Data
    const initialData = Hooks.useInitialData({
        clientID   : 0,
        accountID  : 0,
        name       : "",
        externalID : "",
    }, customFields);

    // Handles the Set
    const handleSet = () => {
        if (editElem.id) {
            setElem({ ...editElem });
        } else {
            setElem({ ...initialData, clientID });
        }
    };

    // Handles the Final Submit
    const handleFinalSubmit = (response) => {
        const sameElem = !elem.id || elem.id === editElem.id;
        onSubmit(sameElem ? response.accountID : 0, response);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("account", initialData, editAccount, handleFinalSubmit);

    // Load the Data
    const { loading } = useDialog("account", open, elemID, { elemID, clientID }, handleSet, fetchEditData);


    // Do the Render
    return <EditDialog
        open={open}
        icon="account"
        title={elemID ? "ACCOUNTS_EDIT_TITLE" : "ACCOUNTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount={customFields.length < 3 ? "1" : "2"}>
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="externalID"
                label="ACCOUNTS_EXTERNAL_ID"
                value={data.externalID}
                error={errors.externalID}
                onChange={handleChange}
            />

            <FieldsInputs
                fields={customFields}
                data={data}
                errors={errors}
                onChange={handleChange}
                withRequired
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default AccountEdit;
