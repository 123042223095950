import React                from "react";
import PropTypes            from "prop-types";
import CustomFields         from "Utils/CustomFields";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Fields Inputs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FieldsInputs(props) {
    const {
        fields, data, errors, tongueID,
        onError, onChange, withRequired, forFilter,
    } = props;


    // Variables
    const visibleFields = fields.filter((field) => {
        return !field.isHidden && CustomFields.canFilter(field, forFilter);
    });
    const amount = visibleFields.length;


    // Handles the Clear
    const onClear = (name) => {
        onChange(name, "");
    };

    // Returns the Class Name to align the Custom Fields
    const getClassName = (index) => {
        if (amount % 2 !== 1) {
            return "";
        }
        if (index === amount - 1 || CustomFields.canCompare(visibleFields[index + 1], forFilter)) {
            return "columns-double";
        }
        return "";
    };

    // Returns the value of the Custom Field
    const getValue = (field, suffix = "") => {
        if (field.inputType === "file") {
            return data[field.customFieldID]?.name ?? (data[field.customFieldID] ?? "");
        }
        if (CustomFields.canCompare(field, forFilter)) {
            return data[`${field.customFieldID}-${suffix}`];
        }
        const key = CustomFields.getKey(field, tongueID);
        return data[key];
    };



    // Do the Render
    return <>
        {visibleFields.map((field, index) => {
            if (CustomFields.canCompare(field, forFilter)) {
                return <InputField
                    key={field.customFieldID}
                    className="columns-double"
                    type="double"
                    columns={2}
                    label={field.name}
                    error={errors[field.customFieldID]}
                    onChange={onChange}
                >
                    <InputItem
                        type={field.inputType}
                        name={`${field.customFieldID}-from`}
                        prefixText="GENERAL_FROM"
                        value={getValue(field, "from")}
                    />
                    <InputItem
                        type={field.inputType}
                        name={`${field.customFieldID}-to`}
                        prefixText="GENERAL_TO"
                        value={getValue(field, "to")}
                    />
                </InputField>;
            }

            return <InputField
                key={field.customFieldID}
                className={getClassName(index)}
                type={field.inputType}
                columns={2}
                name={CustomFields.getKey(field, tongueID)}
                label={field.name}
                options={field.select}
                value={getValue(field)}
                error={errors[field.customFieldID]}
                onChange={onChange}
                onClear={onClear}
                onError={onError}
                maxSize={process.env.REACT_APP_MAX_SIZE}
                withBorder={field.inputType !== "fields"}
                isRequired={withRequired && field.isRequired}
            >
                <InputItem name="key" />
                <InputItem name="value" />
            </InputField>;
        })}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FieldsInputs.propTypes = {
    fields       : PropTypes.array.isRequired,
    data         : PropTypes.object.isRequired,
    tongueID     : PropTypes.number,
    errors       : PropTypes.object,
    onChange     : PropTypes.func.isRequired,
    onError      : PropTypes.func,
    withRequired : PropTypes.bool,
    forFilter    : PropTypes.bool,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
FieldsInputs.defaultProps = {
    errors       : {},
    withRequired : false,
    forFilter    : false,
};

export default FieldsInputs;
