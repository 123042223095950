import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The WABA Template Buttons
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateButtons(props) {
    const { isDisabled, isoCode, getValue, getError, onChange } = props;

    const { maxButtons, buttonLength } = Store.useState("wabaTemplate");


    // The Current State
    const [ dontTextEdited, setDontTextEdited ] = React.useState(false);


    // Handles the Change
    const handleChange = (name, value) => {
        onChange("Buttons", name, value);
        if (name === "dontSendButtonText") {
            setDontTextEdited(true);
        }
    };


    // Variables
    const hasDontSend        = getValue("addDontSendButton");
    const dontSendButtonText = getValue("dontSendButtonText") || (!dontTextEdited ? NLS.getForLang("WABA_TEMPLATES_DONT_SEND_TEXT", isoCode) : "");
    const buttonLinks        = JSON.parse(getValue("buttonLinks", "[]")).filter((elem) => !!elem.type);
    const hasLinks           = buttonLinks.length > 0;


    // Do the Render
    return <Columns amount="2">
        <InputField
            className="columns-double"
            type="list"
            name="buttonList"
            label="GENERAL_BUTTONS"
            addButton="GENERAL_ADD_BUTTON"
            value={getValue("buttonList", "['']")}
            error={getError("buttonList")}
            onChange={handleChange}
            isDisabled={isDisabled}
            maxAmount={maxButtons}
            maxLength={buttonLength}
            isSortable
        />

        <InputField
            className="columns-double"
            type="fields"
            name="buttonLinks"
            title="WABA_TEMPLATES_LINK_TITLE"
            addButton="WABA_TEMPLATES_ADD_LINK"
            value={getValue("buttonLinks", "[{}]")}
            errors={getError("buttonLinks", false, true)}
            columns="3"
            onChange={handleChange}
            isDisabled={isDisabled}
            maxAmount={maxButtons}
            isSortable
            withBorder
        >
            <InputItem
                type="select"
                name="type"
                label="GENERAL_TYPE"
                options="SELECT_WABA_TEMPLATES_LINK_TYPES"
                isRequired
            />
            <InputItem
                type="text"
                name="text"
                label="WABA_TEMPLATES_BUTTON_TEXT"
                maxLength={buttonLength}
            />
            <InputItem
                hide={(elem) => elem.type !== "PHONE_NUMBER"}
                type="text"
                name="phone"
                label="WABA_TEMPLATES_PHONE_NUMBER"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.type !== "URL"}
                type="text"
                name="url"
                label="WABA_TEMPLATES_URL"
                isRequired
            />
        </InputField>

        <InputField
            isHidden={!hasLinks}
            className="columns-double"
            type="toggle"
            name="showLinksFirst"
            label="WABA_TEMPLATES_SHOW_LINKS_FIRST"
            value={getValue("showLinksFirst")}
            onChange={handleChange}
            isDisabled={isDisabled}
            withBorder
        />

        <InputField
            className={!hasDontSend ? "columns-double" : ""}
            type="toggle"
            name="addDontSendButton"
            label="WABA_TEMPLATES_ADD_DONT_SEND_BUTTON"
            value={getValue("addDontSendButton")}
            onChange={handleChange}
            isDisabled={isDisabled}
            withBorder
        />
        <InputField
            isHidden={!hasDontSend}
            name="dontSendButtonText"
            label="WABA_TEMPLATES_DONT_SEND_BUTTON_TEXT"
            value={dontSendButtonText}
            onChange={handleChange}
            maxLength={buttonLength}
            isDisabled={isDisabled}
            isRequired
        />
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateButtons.propTypes = {
    isDisabled : PropTypes.bool.isRequired,
    isoCode    : PropTypes.string.isRequired,
    getValue   : PropTypes.func.isRequired,
    getError   : PropTypes.func.isRequired,
    onChange   : PropTypes.func.isRequired,
};

export default WabaTemplateButtons;
