import NLS                  from "Dashboard/Core/NLS";
import Navigate             from "Dashboard/Core/Navigate";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import CustomFields         from "Utils/CustomFields";



/**
 * Converts a Status to a Url
 * @param {String}  status
 * @param {Boolean} isAnyAdmin
 * @returns {String}
 */
function statusToUrl(status, isAnyAdmin) {
    switch (status) {
    case "Progress":
        return "PROGRESS";
    case "Followup":
        return "FOLLOWUP";
    case "Resolved":
        return "RESOLVED";
    case "Flow":
        return isAnyAdmin ? "IN_FLOW" : "QUEUE";
    default:
        return "QUEUE";
    }
}

/**
 * Converts a Url to a Status
 * @param {String} url
 * @returns {String}
 */
function urlToStatus(url) {
    if (Navigate.isUrl("PROGRESS", url)) {
        return "Progress";
    }
    if (Navigate.isUrl("FOLLOWUP", url)) {
        return "Followup";
    }
    if (Navigate.isUrl("RESOLVED", url)) {
        return "Resolved";
    }
    if (Navigate.isUrl("IN_FLOW", url)) {
        return "Flow";
    }
    return "Queue";
}

/**
 * Checks if the given Url is a valid Status Url
 * @param {String} url
 * @returns {Boolean}
 */
function isValidStatusUrl(url) {
    return (
        Navigate.isUrl("QUEUE", url) ||
        Navigate.isUrl("PROGRESS", url) ||
        Navigate.isUrl("FOLLOWUP", url) ||
        Navigate.isUrl("RESOLVED", url) ||
        Navigate.isUrl("IN_FLOW", url)
    );
}



/**
 * Returns the Color for the given Qualification
 * @param {String} qualification
 * @returns {String}
 */
function getQualificationColor(qualification) {
    switch (qualification) {
    case "bad":
        return "red";
    case "good":
        return "yellow";
    case "excellent":
        return "green";
    default:
        return "";
    }
}

/**
 * Parses a single Conversation
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate        = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime    = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.lastReplyText      = DateTime.formatShort(elem.lastMessageTime);
    elem.qualificationName  = NLS.get("SELECT_CONVERSATION_QUALIFICATIONS", elem.qualification);
    elem.qualificationIcon  = `qualification-${elem.qualification}`;
    elem.qualificationColor = getQualificationColor(elem.qualification);
    elem.expiredColor       = DateTime.getExpiredColor(elem.lastMessageTime, 12, 24);
    elem.isUnread           = elem.lastMessageTime > elem.readTime;

    elem.externalAd = elem.externalAdID;
    if (elem.externalAdTitle) {
        elem.externalAd = `${elem.externalAdTitle} (${elem.externalAdID})`;
    }

    CustomFields.parseElem(elem);
    Commons.parseTags(elem);
    return elem;
}

/**
 * Parses the Conversations
 * @param {Object[]} conversation
 * @returns {Object[]}
 */
function parseList(conversation) {
    return Utils.parseList(conversation, (elem) => {
        const tags  = Object.values(elem.tags || {});
        const items = [
            `<b>#${elem.number}</b>`,
            `<i>${DateTime.formatDate(elem.createdTime, "dashesTime")}</i>`,
        ];

        if (elem.userID) {
            items.push(elem.userName);
        }
        if (elem.teamID) {
            items.push(elem.teamName);
        }
        if (tags.length) {
            items.push(tags.map(({ name }) => name).join(", "));
        }
        elem.message = items.join(" - ");
    });
}

/**
 * Returns a text for the given Action
 * @param {Object} action
 * @returns {String}
 */
function getActionText(action) {
    const string = NLS.get("SELECT_CONVERSATION_ACTIONS", action.actionType);
    return NLS.format(
        string,
        action.credentialName ? action.credentialName : NLS.get("CONVERSATIONS_BOT"),
        action.paramName,
        DateTime.formatDate(action.createdTime, "time"),
    );
}




// The public API
export default {
    statusToUrl,
    urlToStatus,
    isValidStatusUrl,

    parseElem,
    parseList,
    getActionText,
};
