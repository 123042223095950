import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";
import { App }              from "Utils/API";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";



/**
 * The User Create Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserCreate(props) {
    const { open, clientID, partnerID, onClose, onSubmit } = props;

    const { teams, colors, accesses, statuses, hasEmail } = Store.useState("user");
    const { createUser } = Store.useAction("user");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        clientID       : 0,
        partnerID      : 0,
        access         : "",
        credentialID   : 0,
        credentialName : "",
        createNew      : 0,
        firstName      : "",
        lastName       : "",
        email          : "",
        phone          : "",
        password       : "",
        reqPassChange  : 0,
        color          : "#f2f2f2",
        teams          : [],
        observations   : "",
        emailSignature : "",
        status         : "Active",
    };

    // Handles the Set
    const handleSet = () => {
        setTab("info");
        setElem({ ...initialData, clientID, partnerID });
    };

    // Handles the Final Submit
    const handleFinalSubmit = (response) => {
        onSubmit(response.userID, response);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("user", initialData, createUser, handleFinalSubmit, false, open);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("user", open, 0, { elemID : 0, clientID, partnerID }, handleSet);



    // Do the Render
    const totalErrors = Object.values(errors).filter((elem) => !!elem).length;

    return <>
        <EditDialog
            open={open}
            icon="user"
            title="USERS_CREATE_TITLE"
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <TabList
                isHidden={!hasEmail}
                variant="lined"
                selected={tab}
                onClick={setTab}
                inDialog
            >
                <TabItem
                    message="GENERAL_INFO"
                    icon="info"
                    value="info"
                    badge={totalErrors}
                />
                <TabItem
                    message="EMAIL_SIGNATURES_SINGULAR"
                    icon="email"
                    value="email"
                />
            </TabList>

            <Columns isHidden={tab !== "info"}>
                <InputField
                    className="columns-double"
                    type="select"
                    name="access"
                    label="GENERAL_ACCESS"
                    options={accesses}
                    value={data.access}
                    error={errors.access}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    className="columns-double"
                    type="toggle"
                    name="createNew"
                    label="USERS_NEW"
                    value={!!data.createNew}
                    onChange={handleChange}
                    withBorder
                />
                <InputField
                    isHidden={!!data.createNew}
                    className="columns-double"
                    type="suggest"
                    name="credentialName"
                    label="USERS_SEARCH"
                    suggestID="credentialID"
                    suggestFetch={App.searchUser}
                    suggestParams={{ onlyUsers : 1 }}
                    value={data.credentialName}
                    error={errors.credentialID}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    isHidden={!data.createNew}
                    name="firstName"
                    label="GENERAL_FIRST_NAME"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!data.createNew}
                    name="lastName"
                    label="GENERAL_LAST_NAME"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!data.createNew}
                    type="email"
                    name="email"
                    label="GENERAL_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!data.createNew}
                    type="tel"
                    name="phone"
                    label="GENERAL_CELLPHONE"
                    value={data.phone}
                    onChange={handleChange}
                    autoComplete="new-password"
                />
                <InputField
                    isHidden={!data.createNew}
                    type="password"
                    name="password"
                    label="GENERAL_PASSWORD"
                    value={data.password}
                    error={errors.password}
                    onChange={handleChange}
                    autoComplete="new-password"
                    generateCode
                    isRequired
                />
                <InputField
                    isHidden={!data.createNew}
                    type="toggle"
                    name="reqPassChange"
                    label="GENERAL_REQ_PASS_CHANGE"
                    value={!!data.reqPassChange}
                    onChange={handleChange}
                    withBorder
                />

                <InputField
                    isHidden={!teams.length}
                    className="columns-double"
                    type="multiple"
                    name="teams"
                    label="TEAMS_NAME"
                    options={teams}
                    value={data.teams}
                    error={errors.teams}
                    onChange={handleChange}
                />
                <InputField
                    className="columns-double"
                    type="color"
                    name="color"
                    label="GENERAL_COLOR"
                    options={colors}
                    value={data.color}
                    error={errors.color}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    className="columns-double"
                    type="textarea"
                    name="observations"
                    label="GENERAL_OBSERVATIONS"
                    value={data.observations}
                    onChange={handleChange}
                />
                <InputField
                    className="columns-double"
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <EditorField
                isHidden={tab !== "email"}
                name="emailSignature"
                value={data.emailSignature}
                error={errors.emailSignature}
                clientID={clientID}
                maxHeight={400}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            clientID={clientID}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserCreate.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default UserCreate;
