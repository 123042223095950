import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The WABA Template Main
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateMain(props) {
    const { isDisabled, getValue, getError, onChange } = props;

    const { channels } = Store.useState("wabaTemplate");


    // Handles the Change
    const handleChange = (name, value) => {
        onChange("Main", name, value);
    };


    // Do the Render
    return <Columns amount="1">
        <InputField
            isHidden={!channels.length}
            type="select"
            name="channelID"
            label="CHANNELS_SINGULAR"
            options={channels}
            value={getValue("channelID")}
            error={getError("channelID")}
            onChange={handleChange}
            isDisabled={isDisabled}
            isRequired
        />

        <InputField
            type="radio"
            name="category"
            label="GENERAL_CATEGORY"
            options="SELECT_WABA_TEMPLATES_CATEGORIES_DESC"
            value={getValue("category")}
            error={getError("category")}
            onChange={handleChange}
            isDisabled={isDisabled}
            isRequired
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={getValue("name")}
            error={getError("name")}
            onChange={handleChange}
            isDisabled={isDisabled}
            isRequired
        />
        <InputField
            type="toggle"
            name="forIntegration"
            label="WABA_TEMPLATES_FOR_INTEGRATIONS"
            value={getValue("forIntegration")}
            onChange={handleChange}
            withBorder
        />
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateMain.propTypes = {
    isDisabled : PropTypes.bool.isRequired,
    getValue   : PropTypes.func.isRequired,
    getError   : PropTypes.func.isRequired,
    onChange   : PropTypes.func.isRequired,
};

export default WabaTemplateMain;
