import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Colors               from "Utils/Colors";
import CustomFields         from "Utils/CustomFields";



/**
 * Formats the given Price
 * @param {Number}   price
 * @param {String=}  symbol
 * @param {Number=}  decimals
 * @param {Boolean=} showFree
 * @returns {String}
 */
function formatPrice(price, symbol = "$", decimals = 0, showFree = true) {
    if (price === 0 && showFree) {
        return NLS.get("GENERAL_FREE");
    }
    return `${symbol} ${Utils.formatNumber(price, decimals)}`;
}

/**
 * Returns true if at least one of the given Connectors is active
 * @param {Object[]} connectors
 * @returns {Boolean}
 */
function hasConnectors(connectors) {
    return Object.values(connectors).filter(({ isActive }) => isActive).length > 0;
}

/**
 * Returns true if at least one of the given Connectors is active and can be added
 * @param {Object[]} connectors
 * @returns {Boolean}
 */
function hasConnectorTypes(connectors) {
    return Object.values(connectors).filter(({ isActive, canAdd }) => isActive && canAdd).length > 0;
}

/**
 * Parses the Tags
 * @param {Object} elem
 * @returns {Void}
 */
function parseTags(elem) {
    elem.tagList  = Object.values(elem.tags || {}).sort((a, b) => a.name.localeCompare(b.name));
    elem.tagNames = elem.tagList.join(", ");
    elem.hasTags  = elem.tagList.length > 0;
}

/**
 * Parses the Qualifications
 * @param {Object} elem
 * @returns {Void}
 */
function parseQualifications(elem) {
    const percent = elem.qualificationPercent;

    elem.qualificationPercent = elem.totalQualifications > 0 ? `${elem.qualificationPercent}%` : "-";
    elem.qualificationIcon    = "qualification-good";
    elem.qualificationColor   = "";
    elem.qualificationTooltip = "";

    if (elem.totalQualifications > 0) {
        elem.qualificationTooltip = `
            <nobr>
                <b>${NLS.get("CONVERSATIONS_QUALIFICATION_BAD")}</b>:
                ${Utils.formatPercent(elem.badQualifications, elem.totalQualifications)}
                (${elem.badQualifications})
            </nobr><br>
            <nobr>
                <b>${NLS.get("CONVERSATIONS_QUALIFICATION_GOOD")}</b>:
                ${Utils.formatPercent(elem.goodQualifications, elem.totalQualifications)}
                (${elem.goodQualifications})
            </nobr><br>
            <nobr>
                <b>${NLS.get("CONVERSATIONS_QUALIFICATION_EXCELLENT")}</b>:
                ${Utils.formatPercent(elem.excellentQualifications, elem.totalQualifications)}
                (${elem.excellentQualifications})
            </nobr>
        `;

        if (percent < 33) {
            elem.qualificationColor = "text-red";
            elem.qualificationIcon  = "qualification-bad";
        } else if (percent < 66) {
            elem.qualificationColor = "text-yellow";
            elem.qualificationIcon  = "qualification-good";
        } else {
            elem.qualificationColor = "text-green";
            elem.qualificationIcon  = "qualification-excellent";
        }
    }
}



/**
 * Parses a Contact
 * @param {Object} elem
 * @returns {Object}
 */
function parseContact(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.dontSendText    = elem.dontSendCampaign ? NLS.get("CONTACTS_DONT_SEND_CAMPAIGNS") : "";
    elem.isBlockedText   = elem.isBlocked ? NLS.get("CONTACTS_BLOCKED") : "";
    elem.providerItems   = getProviderItems(elem);

    CustomFields.parseElem(elem);
    parseTags(elem);
    parseQualifications(elem);
    return elem;
}

/**
 * Parses a Hospitality
 * @param {Object} elem
 * @returns {Object}
 */
function parseHospitality(elem) {
    elem.createdDate      = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime  = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.arrivalDate      = DateTime.formatIf(elem.arrivalTime,   "dashesReverse");
    elem.departureDate    = DateTime.formatIf(elem.departureTime, "dashesReverse");
    elem.fromDate         = DateTime.formatIf(elem.fromTime,      "dashes");
    elem.toDate           = DateTime.formatIf(elem.toTime,        "dashes");
    elem.name             = NLS.format("HOSPITALITY_ITS_NAME", elem.statusName, elem.contactName);
    elem.nightsText       = NLS.pluralize("HOSPITALITY_NIGHTS", elem.nights);

    const currentTime     = Utils.getCurrentTime();
    const fromArrival     = currentTime - elem.createdTime;
    const toArrival       = elem.fromTime - elem.createdTime;
    const fromDeparture   = currentTime - elem.fromTime;
    const toDeparture     = elem.toTime - elem.fromTime;

    elem.hasTime          = Boolean(!elem.isCancelled && elem.fromTime && elem.departureTime);
    elem.isBooking        = currentTime < elem.fromTime;
    elem.isCheckedIn      = currentTime > elem.fromTime;
    elem.isCheckedOut     = currentTime > elem.toTime;
    elem.bookingPercent   = elem.isBooking   ? Math.round(Math.min(100, fromArrival * 100 / toArrival))     : 100;
    elem.checkedInPercent = elem.isCheckedIn ? Math.round(Math.min(100, fromDeparture * 100 / toDeparture)) : 0;

    CustomFields.parseElem(elem);
    return elem;
}

/**
 * Parses a Quotation
 * @param {Object} elem
 * @returns {Object}
 */
function parseQuotation(elem) {
    elem.createdDate      = DateTime.formatDate(elem.createdTime,   "dashes");
    elem.createdDateTime  = DateTime.formatDate(elem.createdTime,   "dashesTime");
    elem.arrivalDate      = DateTime.formatDate(elem.arrivalTime,   "dashes");
    elem.departureDate    = DateTime.formatDate(elem.departureTime, "dashes");
    elem.offerPriceText   = elem.offerPrice   ? formatPrice(elem.offerPrice,   elem.currency, 2) : "";
    elem.regularPriceText = elem.regularPrice ? formatPrice(elem.regularPrice, elem.currency, 2) : "";
    return elem;
}

/**
 * Parses an Order
 * @param {Object} elem
 * @returns {Object}
 */
function parseOrder(elem) {
    elem.name            = NLS.format("ORDERS_ITS_NAME", elem.orderNumber);
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.orderNumberText = `#${elem.orderNumber}`;
    elem.totalPriceText  = Utils.formatPrice(elem.totalPrice);

    for (const item of Object.values(elem.items)) {
        item.unitPriceText  = Utils.formatPrice(item.unitPrice);
        item.totalPriceText = Utils.formatPrice(item.totalPrice);
    }
    return elem;
}

/**
 * Parses the Schedule
 * @param {Object[]} periods
 * @param {Boolean=} useTimezone
 * @returns {String}
 */
function parseSchedule(periods, useTimezone = true) {
    if (Utils.isEmpty(periods)) {
        return "";
    }

    const dateTime  = DateTime.create();
    const schedules = {};
    const list      = [];

    for (const elem of periods) {
        if (!elem.days) {
            continue;
        }
        for (const day of elem.days) {
            const date     = dateTime.toWeekStart(day).toString("dashes");
            const fromDay  = DateTime.fromString(date, elem.from, useTimezone);
            const fromHour = fromDay.toString("time");
            const toHour   = DateTime.formatDateTime(date, elem.to, "time", useTimezone);
            const id       = `${fromHour}-${toHour}`;

            if (!schedules[id]) {
                schedules[id] = { fromHour, toHour, days : [] };
            }
            schedules[id].days.push(fromDay.getDayName());
        }
    }

    const elements = Object.values(schedules);
    for (const elem of elements) {
        if (elem.days.length === 1) {
            list.push(NLS.format("SCHEDULES_DATE_TIME", elem.days[0], elem.fromHour, elem.toHour));
        } else {
            const days = NLS.join(elem.days);
            list.push(NLS.format("SCHEDULES_DATE_TIME", days, elem.fromHour, elem.toHour));
        }
    }

    return NLS.join(list);
}

/**
 * Parses the Time Zone
 * @param {(Number|String)} timeZone
 * @returns {String}
 */
function parseTimeZone(timeZone) {
    const sign    = Number(timeZone) < 0 ? "-" : "+";
    const time    = Math.abs(Number(timeZone) * 60);
    const hours   = Math.floor(time / 60);
    const minutes = time - hours * 60;

    return `GMT ${sign}${hours}:${DateTime.parseTime(minutes)}`;
}



/**
 * Adds/Removes Messages/Actions to the old ones
 * @param {String}    idKey
 * @param {Object[]}  oldList
 * @param {Object[]}  newList
 * @param {Number[]=} deletedIDs
 * @returns {Object[]}
 */
function updateList(idKey, oldList, newList, deletedIDs) {
    const result = oldList.slice(0);
    if (newList && newList.length) {
        for (const newElem of newList) {
            let found = false;
            for (const [ index, oldElem ] of result.entries()) {
                if (newElem[idKey] === oldElem[idKey]) {
                    result[index] = newElem;
                    found = true;
                    break;
                }
            }
            if (!found) {
                result.push(newElem);
            }
        }
    }

    if (deletedIDs && deletedIDs.length) {
        for (const deletedID of deletedIDs) {
            let removeIndex = -1;
            for (const [ index, oldElem ] of result.entries()) {
                if (deletedID === oldElem[idKey]) {
                    removeIndex = index;
                    break;
                }
            }
            if (removeIndex > -1) {
                result.splice(removeIndex, 1);
                break;
            }
        }
    }
    return result;
}

/**
 * Returns a Platform Select from a list of Channels
 * @param {Object[]} channels
 * @returns {Object[]}
 */
function getPlatformSelect(channels) {
    const platforms = {};
    const result    = [];

    for (const { providerName } of channels) {
        if (!platforms[providerName]) {
            platforms[providerName] = 1;
            result.push({
                key   : providerName,
                value : providerName,
            });
        }
    }
    result.sort((a, b) => a.value.localeCompare(b.value));
    return result;
}

/**
 * Returns a Channel Select from a list of Channels
 * @param {Object[]} channels
 * @param {Boolean=} useFullName
 * @returns {Object[]}
 */
function getChannelSelect(channels, useFullName = true) {
    return channels.map((elem) => ({
        key   : elem.id,
        value : useFullName ? elem.fullName : Utils.makeBreakable(elem.nameProvider),
    })).sort((a, b) => a.value.localeCompare(b.value));
}

/**
 * @private
 * Returns a List of Provider Items
 * @param {Object} elem
 * @returns {Array.<{key: Number, type: String}>}
 */
function getProviderItems(elem) {
    const items     = [];
    const providers = {};

    if (elem.hasWhatsApp && elem.cellphone) {
        items.push({
            key   : -1,
            type  : "WhatsApp",
            icon  : "whatsapp",
            color : Colors.WHATSAPP,
        });
    }

    if (elem.hasSMS && elem.cellphone) {
        items.push({
            key   : -2,
            type  : "SMS",
            icon  : "sms",
            color : Colors.SMS,
        });
    }

    if (elem.hasEmail && elem.email) {
        items.push({
            key   : -3,
            type  : "Email",
            icon  : "email",
            color : Colors.EMAIL,
        });
    }

    if (elem.providers) {
        for (const provider of elem.providers) {
            if (providers[provider.providerType] || provider.isDeleted || !provider.providerType) {
                continue;
            }
            providers[provider.providerType] = true;
            items.push({
                key   : provider.providerID,
                type  : provider.providerType,
                icon  : provider.providerIcon,
                color : provider.providerColor,
            });
        }
    }
    return items;
}



/**
 * Returns the Time as a String
 * @param {Number} time
 * @returns {String}
 */
function getDayString(time) {
    const options = { month : "short", day : "numeric", hour : "numeric", minute : "numeric" };
    const date    = new Date(time * 1000);
    const now     = new Date();
    if (now.getFullYear() !== date.getFullYear())  {
        options.year = "numeric";
    }
    // @ts-ignore
    return date.toLocaleString(undefined, options);
}

/**
 * Returns the Import Columns
 * @param {Array.<{key: String, value: String}>} columns
 * @param {Array.<{key: String, value: String}>} fields
 * @returns {Object.<String, Number>}
 */
function getImportColumns(columns, fields) {
    const result = {};
    for (const { key, value } of columns) {
        const name  = String(value).toLocaleLowerCase();
        const field = fields.find(({ value }) => name === NLS.get(value).toLocaleLowerCase());
        if (field) {
            result[field.key] = key;
        }
    }
    return result;
}



/**
 * Scrolls and Highlights the Node
 * @param {Number}  nodeID
 * @param {String=} behavior
 * @returns {Void}
 */
function scrollToNode(nodeID, behavior = "smooth") {
    const className = `.flow-node-${nodeID}`;
    const node      = document.querySelector(className);

    if (node) {
        node.scrollIntoView({
            // @ts-ignore
            behavior : behavior,
            block    : "center",
            inline   : "center",
        });

        node.classList.add("highlight");
        window.setTimeout(() => {
            node.classList.remove("highlight");
        }, 2000);
    }
}

/**
 * Returns the Filtered WABA Templates
 * @param {Object[]} wabaTemplates
 * @param {Number}   filterChannelID
 * @returns {Object[]}
 */
function getWabaTemplates(wabaTemplates, filterChannelID) {
    const result = [];
    for (const { id, name, channelID } of wabaTemplates) {
        if (channelID !== Number(filterChannelID)) {
            continue;
        }
        result.push({ key : id, value : name });
    }
    return result;
}

/**
 * Creates a WABA Template Message
 * @param {Object}  wabaTemplate
 * @param {String=} isoCode
 * @returns {String}
 */
function getWabaMessage(wabaTemplate, isoCode = "es") {
    let component = Utils.getValue(wabaTemplate.components, "isoCode", isoCode || "es");
    if (!component) {
        component = wabaTemplate.components[0];
    }

    let result = "";
    if (component.headerText) {
        result += `*${component.headerText}*\n\n`;
        result = replaceWabaVariables(result, [ component.variableName ]);
    }
    if (component.bodyText) {
        result += component.bodyText;
        const variables = Utils.getValues(wabaTemplate.variables, "wabaComponentID", component.wabaComponentID);
        if (variables.length) {
            const samples = variables.map(({ variableName }) => variableName);
            result = replaceWabaVariables(result, samples);
        }
    }
    if (component.footerText) {
        result += `\n\n_${component.footerText}_`;
    }

    result = result.replace(/\n/g, "<br>");
    return formatWabaText(result);
}

/**
 * Formats the given WABA Template text
 * @param {String} text
 * @returns {String}
 */
function formatWabaText(text) {
    return text
        .replace(/\*([^*]+)\*/g, "<b>$1</b>")
        .replace(/_([^_]+)_/g, "<i>$1</i>")
        .replace(/~([^~]+)~/g, "<s>$1</s>")
        .replace(/```([^`]+)```/g, "<code>$1</code>");
}

/**
 * Replaces the WABA Template variables
 * @param {String}   text
 * @param {String[]} samples
 * @returns {String}
 */
function replaceWabaVariables(text, samples) {
    let result = text;
    for (const [ index, sample ] of samples.entries()) {
        const variable = `{{${index + 1}}}`;
        result = result.replace(variable, sample || variable);
    }
    return result;
}



/**
 * Returns true if the Text is GMS encoded
 * @param {String} text
 * @returns {Boolean}
 */
function isGSMAlphabet(text) {
    // spell-checker: disable-next-line
    const regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
    return regexp.test(text);
}

/**
 * Calculates the SMS Cost for the given message
 * @param {String} text
 * @param {Number} smsCost
 * @returns {String}
 */
function calculateSMSCost(text, smsCost) {
    const divider  = isGSMAlphabet(text) ? 153 : 67;
    const segments = Math.ceil(text.length / divider);
    const cost     = segments * smsCost;
    const result   = Math.ceil(cost * 100) / 100;
    return String(result);
}



/**
 * Returns the Error Count in the Step
 * @param {Object}  fields
 * @param {Object}  errors
 * @param {String}  step
 * @param {Number=} tongueID
 * @param {Number=} index
 * @returns {Number}
 */
function getErrorCount(fields, errors, step, tongueID, index) {
    let result = 0;
    if (!fields[step]) {
        return result;
    }

    for (const field of Object.keys(errors)) {
        if (!errors[field]) {
            continue;
        }
        if (fields[step].includes(field)) {
            result += 1;
            continue;
        }

        const parts = field.split("-");
        if (!fields[step].includes(parts[0])) {
            continue;
        }

        if (index !== undefined) {
            if (Number(parts[1]) === index && Number(parts[2]) === tongueID) {
                result += 1;
            }
        } else {
            if (tongueID && Number(parts[1]) === tongueID) {
                result += 1;
            } else if (!tongueID) {
                result += 1;
            }
        }
    }
    return result;
}

/**
 * Returns a Language Error
 * @param {Array}  tongues
 * @param {Object} errors
 * @param {Number} tongueIndex
 * @returns {String}
 */
function getLanguageError(tongues, errors, tongueIndex) {
    const languages = [];
    for (const { key, value } of tongues) {
        for (const [ field, error ] of Object.entries(errors)) {
            if (error) {
                const parts = field.split("-");
                if (parts.length === tongueIndex + 1 && Number(parts[tongueIndex]) === key) {
                    languages.push(value);
                    break;
                }
            }
        }
    }
    if (languages.length > 0) {
        return NLS.pluralizeList("GENERAL_ERROR_LANGUAGE", languages);
    }
    return "";
}

/**
 * Creates an Url adding the Params to de Base Url
 * @param {String} baseUrl
 * @param {Object} params
 * @returns {String}
 */
function createUrl(baseUrl, params) {
    const parts = [];
    for (const [ key, value ] of Object.entries(params)) {
        parts.push(`${key}=${value}`);
    }
    return `${baseUrl}?${parts.join("&")}`;
}

/**
 * Opens a Window
 * @param {String}  url
 * @param {*}       handler
 * @param {Number=} height
 * @param {Number=} width
 * @returns {Object}
 */
function openWindow(url, handler, height = 700, width = 600) {
    const win = window.open(
        url,
        "Conversana",
        `toolbar=no, menubar=no, width=${width}, height=${height}, top=50, left=50`
    );
    const winTimer = window.setInterval(() => {
        if (win.closed) {
            window.clearInterval(winTimer);
        }
    }, 100);

    window.removeEventListener("message", handler);
    window.addEventListener("message", handler, false);
    return win;
}




// The public API
export default {
    formatPrice,
    hasConnectors,
    hasConnectorTypes,
    parseTags,
    parseQualifications,
    parseContact,
    parseHospitality,
    parseQuotation,
    parseOrder,
    parseSchedule,
    parseTimeZone,

    updateList,
    getPlatformSelect,
    getChannelSelect,
    getProviderItems,

    getDayString,
    getImportColumns,

    scrollToNode,
    getWabaTemplates,
    getWabaMessage,
    formatWabaText,
    replaceWabaVariables,
    calculateSMSCost,

    getErrorCount,
    getLanguageError,
    createUrl,
    openWindow,
};
