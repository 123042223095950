import Store                from "Dashboard/Core/Store";
import { App }              from "Utils/API";



// The initial State
const initialState = {
    announcement      : {},
    documentUrls      : {},

    showChatSupport   : false,
    chatSupportUnread : 0,
};



// The Actions
const actions = {
    /**
     * Fetches the Initial Data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchInitial(dispatch) {
        const data = await App.getInitial();
        dispatch({ type : "APP_INITIAL_DATA", data });
    },

    /**
     * Fetches the Progress
     * @param {Function} dispatch
     * @returns {Promise}
     */
    fetchProgress(dispatch) {
        return App.getProgress();
    },

    /**
     * Discards the Announcement
     * @param {Function} dispatch
     * @param {Number}   announcementID
     * @returns {Promise}
     */
    discardAnnouncement(dispatch, announcementID) {
        return App.discardAnnouncement({ announcementID });
    },


    /**
     * Sets the Show Chat Support
     * @param {Function} dispatch
     * @param {Boolean}  show
     * @returns {Void}
     */
    setShowChatSupport(dispatch, show) {
        dispatch({ type : "APP_SHOW_CHAT_SUPPORT", show });
    },

    /**
     * Sets the Chat Support Unread
     * @param {Function} dispatch
     * @param {Number}   unread
     * @returns {Void}
     */
    setChatSupportUnread(dispatch, unread) {
        dispatch({ type : "APP_CHAT_SUPPORT_UNREAD", unread });
    },


    /**
     * Saves the Columns
     * @param {Function} dispatch
     * @param {String}   module
     * @param {Number}   clientID
     * @param {Object[]} columns
     * @param {Boolean}  saveColumns
     * @returns {Void}
     */
    saveColumns(dispatch, module, clientID, columns, saveColumns) {
        dispatch({ type : `${module.toUpperCase()}_COLUMNS`, columns });
        if (saveColumns) {
            App.saveColumns({ module, clientID, columns : JSON.stringify(columns) });
        }
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "APP_INITIAL_DATA":
        return {
            ...state,
            announcement      : action.data.announcement,
            documentUrls      : action.data.documentUrls,
        };

    case "APP_SHOW_CHAT_SUPPORT":
        return {
            ...state,
            showChatSupport   : action.show,
        };

    case "APP_CHAT_SUPPORT_UNREAD":
        return {
            ...state,
            chatSupportUnread : action.unread,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
