import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Commons              from "Utils/Commons";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";
import Html                 from "Dashboard/Components/Common/Html";
import InputError           from "Dashboard/Components/Input/InputError";



// Styles
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
    padding: 16px;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
`;



/**
 * The Connector Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConnectorButton(props) {
    const { label, helperText, successText, url, error, onChange } = props;


    // The Current State
    const [ isLoading,   setLoading     ] = React.useState(false);
    const [ isInstalled, setIsInstalled ] = React.useState({});


    // Load the Data
    React.useEffect(() => {
        setIsInstalled(false);
    }, []);

    // Handles the Click
    const handleClick = () => {
        setLoading(true);
        Commons.openWindow(url, handleMessage, 700, 1000);
    };

    // Handles the Message
    const handleMessage = (event) => {
        if (typeof event.data === "object") {
            return;
        }
        setLoading(false);
        setIsInstalled(true);
        onChange(event.data);
        console.log("Message", event.data);
    };


    // Do the Render
    if (isInstalled) {
        return <Container>
            <Html
                className="text-green"
                message={successText}
            />
        </Container>;
    }

    return <div>
        <Container>
            <Html message={helperText} />
            <Button
                variant="outlined"
                message={label}
                isLoading={isLoading}
                onClick={handleClick}
            />
        </Container>
        <InputError error={error} />
    </div>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConnectorButton.propTypes = {
    label       : PropTypes.string.isRequired,
    helperText  : PropTypes.string.isRequired,
    successText : PropTypes.string.isRequired,
    url         : PropTypes.string.isRequired,
    error       : PropTypes.string.isRequired,
    onChange    : PropTypes.func.isRequired,
};

export default ConnectorButton;
