import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Error Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ErrorLogView(props) {
    const { open, elemID, onClose } = props;

    const { fetchElem, resolveElem, deleteElem } = Store.useAction("errorLog");


    // The Current State
    const [ update,     setUpdate     ] = React.useState(false);
    const [ showDelete, setShowDelete ] = React.useState(false);

    // Load the Data
    const { loading, elem } = useDialog("errorLog", open, elemID);


    // Handles the Mark as Resolved
    const handleResolve = async () => {
        const response = await resolveElem(elemID);
        if (response.success) {
            fetchElem(elemID);
            setUpdate(true);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        const response = await deleteElem(elemID);
        if (response.success) {
            setShowDelete(false);
            onClose(true);
        }
    };

    // Handles the Dialog Close
    const handleClose = () => {
        onClose(update);
    };


    // Do the Render
    return <>
        <ViewDialog
            open={open}
            icon="error-log"
            title="ERRORS_LOG_VIEW_TITLE"
            secondary={!elem.isResolved ? "ERRORS_LOG_RESOLVE_TITLE" : ""}
            onSecondary={handleResolve}
            tertiary="GENERAL_DELETE"
            onTertiary={() => setShowDelete(true)}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns>
                <ViewField
                    label="GENERAL_TIME"
                    value={elem.updatedTimeText}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    viewClass={elem.resolvedClass}
                    value={elem.resolvedText}
                />
                <ViewField
                    label="GENERAL_ONE_ERROR"
                    value={elem.amountErrorText}
                />
                <ViewField
                    label="GENERAL_ENVIRONMENT"
                    value={elem.environment}
                    showEmpty
                />
            </Columns>
            <ViewField
                label="GENERAL_FILE"
                value={elem.fileLineText}
            />
            <ViewField
                label="GENERAL_DESCRIPTION"
                value={elem.description}
            />
            <ViewField
                label="ERRORS_LOG_BACKTRACE"
                value={elem.backtrace}
            />
        </ViewDialog>

        <DeleteDialog
            open={showDelete}
            title="ERRORS_LOG_DELETE_TITLE"
            message={NLS.pluralize("ERRORS_LOG_DELETE_TEXT", 1)}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ErrorLogView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default ErrorLogView;
